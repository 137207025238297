<template xmlns:el-col="http://www.w3.org/1999/html">
  <!--  展示考研的vue-->
  <div class="course">
    <el-container class="content">
      <el-aside width="180px">
        <el-menu
            class="menu"
            default-active="/study/graduate"
            @open="handleOpen"
            @close="handleClose"
            router>
          <el-menu-item index="/study/graduate">
            <i class="el-icon-setting"></i>
            <span slot="title">考研流程</span>
          </el-menu-item>

          <el-menu-item index="/study" >
            <i class="el-icon-setting"></i>
            <span slot="title">查找学校信息</span>
          </el-menu-item>
          <el-menu-item index="/study/category">
            <i class="el-icon-setting"></i>
            <span slot="title">根据专业选学校</span>
          </el-menu-item>

        </el-menu>
      </el-aside>

      <el-main class="con">
        <el-container >
          <router-view></router-view>
        </el-container>
      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "Kaoyan",
  components: {

  },
  data() {
    return {
      choose: 1,
      type: 1,
    }
  },
  mounted() {
  },
  methods: {
    next() {
      if (this.choose++ > 3) this.choose = 0;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }


}
</script>

<style scoped>
.content{
  /*width: 60%;*/
  margin: 0 auto;
}
.con{
  width: 100%;
  overflow: auto;
  background-color: #fff;
}
.course{
  margin-top: 2px;
  text-align: center;
  /*height: 100%;*/
}
.menu{
  /*height: calc(100%);*/
  text-align: left;
}

</style>
